var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group form-group-sm limit-box"},[(_vm.sourceTypeList.length > 1)?_c('div',{staticClass:"input-group",style:({width: _vm.iType == 'constant' ? 'inherit' : '100%'})},[_c('div',{staticClass:"input-group-addon label-addon"},[_c('span',{staticClass:"text-primary text-bold"},[_vm._v(_vm._s(_vm.$t("type")))])]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.iType),expression:"iType"}],staticClass:"form-control form-group-sm no-padding type-selector",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.iType=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.sourceTypeList),function(o){return _c('option',{key:o.id,domProps:{"value":o.id}},[_vm._v(" "+_vm._s(_vm.$t(o.title))+" ")])}),0),(_vm.iType == 'constant')?_c('div',{staticClass:"input-group-addon no-padding"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.iValue),expression:"iValue"}],staticClass:"form-control no-padding constant",attrs:{"type":"number","placeholder":_vm.$t('value')},domProps:{"value":(_vm.iValue)},on:{"input":function($event){if($event.target.composing)return;_vm.iValue=$event.target.value}}})]):_vm._e()]):_vm._e(),(_vm.iType == 'data')?_c('ControlDataSelector',{attrs:{"addon":_vm.$tc('data'),"label":""},model:{value:(_vm.iDataId),callback:function ($$v) {_vm.iDataId=$$v},expression:"iDataId"}}):_vm._e(),(_vm.iDataValueIndex)?_c('ValueSourceSelector',{attrs:{"offset":true,"connectorId":_vm.dataConnectorId,"exclude":[_vm.iDataId]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_c('label',{attrs:{"for":"data_value_index"}},[_vm._v(_vm._s(_vm.$t("titles.data_value_index"))+" "),_c('ToolTip',{attrs:{"title":`${_vm.$t('hints.data_value_index')}<br/>${_vm.$tc(
              'interval',
              1
            )}: &ge; 0 && &le; ${_vm.dataMemorySize - 1}`}})],1),_c('div',{staticClass:"pull-right"},[(_vm.iDataValueIndex.type == 'constant')?_c('span',{staticClass:"small",attrs:{"title":`${_vm.$t('numeric').toLowerCase()}`}},[_vm._v(" ≥ 0 && ≤ "+_vm._s(_vm.dataMemorySize - 1)+"    ")]):_c('span',{staticClass:"small",attrs:{"title":`${_vm.$t('numeric').toLowerCase()} ${_vm.$t(
              'or'
            ).toLowerCase()} ${_vm.$t(
              'expression'
            ).toLowerCase()}\nex: $value*10+3`}},[_c('i',{staticClass:"fa fa-code"})])])])]},proxy:true}],null,false,459301654),model:{value:(_vm.iDataValueIndex),callback:function ($$v) {_vm.iDataValueIndex=$$v},expression:"iDataValueIndex"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }