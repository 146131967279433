<template>
  <div style="margin: 0 5px 0 10px">
    <!-- begin minimum -->
    <TogglePanel :title="$t('minimum')">
      <ValueSourceForm v-model="min" :defaultValue="0" :typeList="typeList" />
    </TogglePanel>
    <!-- end minimum -->
    <!-- begin maximum -->
    <TogglePanel :title="$t('maximum')">
      <ValueSourceForm v-model="max" :defaultValue="100" :typeList="typeList" />
    </TogglePanel>
    <!-- end maximum -->
  </div>
</template>

<script>
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import ValueSourceForm from "@/components/synoptic/property-editor/controls/value-source-form.vue";
import {dftSourceValue} from "@/components/synoptic/property-editor/controls/value-source-form.vue";

export default {
  name: "MinMaxForm",
  props: {
    value: {
      type: Object,
      required: false,
      default: () => null
    },
    typeList: {
      type: Array,
      required: false,
      default: () => ["constant", "data"]
    }
  },
  components: {
    ValueSourceForm,
    TogglePanel
  },
  data: () => ({
    form: {
      min: dftSourceValue(),
      max: dftSourceValue()
    }
  }),
  computed: {
    allowNull() {
      return this.typeList.indexOf("none") >= 0;
    },
    min: {
      set(value) {
        this.form.min = value;
        this.triggerChanges();
      },
      get() {
        return this.form.min;
      }
    },
    max: {
      set(value) {
        this.form.max = value;
        this.triggerChanges();
      },
      get() {
        return this.form.max;
      }
    }
  },
  methods: {
    triggerChanges() {
      const parse = (sourceValue) => {
        if (!sourceValue) return null; // undefined value
        let entry = {
          ...sourceValue,
          data_value_index: {...sourceValue.data_value_index}
        };
        if (sourceValue.type == "constant") {
          delete entry.data_id;
          delete entry.data_value_index;
          entry.value = sourceValue.value;
        } else {
          delete entry.value;
          entry.data_id = sourceValue.data_id;
          entry.data_value_index = sourceValue.data_value_index;
        }
        return entry;
      };
      let payload = {
        min: parse(this.min),
        max: parse(this.max)
      };
      this.$emit("input", payload);
    },
    init() {
      const parse = (sourceValue) => {
        // backward compatibility
        if (!sourceValue && this.allowNull) return null;
        let value = {...(sourceValue || {})};
        let entry = dftSourceValue();
        entry.type = value.type || ("data_id" in value ? "data" : "constant");
        if (entry.type == "constant") {
          entry.value = value.value;
        } else {
          entry.data_id = value.data_id;
          if (value.data_value_index) {
            entry.data_value_index = {...value.data_value_index};
          }
        }
        return entry;
      };
      this.form = {
        min: parse(this.value?.min || null),
        max: parse(this.value?.max || null)
      };
    },
    updateFromData(data) {
      if (!data || !data?.type) return;
      if (data.type == "string") {
        this.min = null;
        this.max = null;
      } else {
        if (
          this.$utils.isEmpty(this?.min?.value) &&
          this?.min?.type == "constant" &&
          !this.$utils.isEmpty(data.minimum_value)
        ) {
          this.min = {...this.min, value: data.minimum_value};
        }
        if (
          this.$utils.isEmpty(this?.max?.value) &&
          this?.max?.type == "constant" &&
          !this.$utils.isEmpty(data.maximum_value)
        ) {
          this.min = {...this.min, value: data.maximum_value};
        }
      }
    }
  },
  created() {
    this.init();
  }
};
</script>

<style scoped>
.limit-box {
  margin-top: 5px;
  margin-left: -5px;
  padding-left: 5px;
}

.label-addon {
  width: 74px;
}

.constant {
  width: 70px;
  height: 28px !important;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  text-align: center;
}

input[type="number"] {
  opacity: 1;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.form-group-sm select.form-control.type-selector {
  height: 31px; /* one pixel extra to compensate any zoom */
  overflow: hidden;
}
</style>